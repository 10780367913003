<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
</style>
<template lang="pug">
div
  .d-flex.justify-content-center.align-items-top(style='min-height: 300px')
    .col-9
      .mb-4
      h3.title.py-3 기본 정보

      form(@submit.prevent='submit')
        .mb-4
        .form-group
          label.d-block 이름
          input.form-control(type='text' v-model='form.name' autofocus)
          p: small.text-muted 서비스 상단과 계정선택 목록에 표시되는 이름입니다.
        .form-group
          button.btn.btn-primary.float-right(type='submit') 저장

</template>

<script>

export default {
  name: 'index',
  props: ['property_id'],
  components: {

  },
  computed: {
    session() {
      return this.$store.state.session
    },
  },
  mounted() {
    this.load()
  },
  data() {
    return {
      property: {},
      form: {

      },
    }
  },
  methods: {
    async load() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}`)
        if (r.data.message != 'ok') throw new Error(r.data.message || '정보 가져오기 실패')

        this.form = r.data.property
        // this.form.name = this.property.name
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async submit() {
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}`, this.form)
        if (r.data.message != 'ok') throw new Error(r.data.message || '저장 실패')

        this.load()
        this.$modal.show('dialog', {
          title: '알림',
          text: '저장했습니다.'
        })
        this.$store.dispatch('status flows', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    }
  },
}
</script>
